import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  messages: [],
  language: null, 
  channel: null,  
};

const conversationMessagesSlice = createSlice({
  name: "messages",
  initialState,
  reducers: {
    addMessage: (state, action) => {
      state.messages.push(action.payload);
    },
    clearMessages: (state) => {
      state.messages = [];
    },
    setSelectedLanguage: (state, action) => {
      state.language = action.payload;
    },
    clearSelectedLanguage: (state) => {
      state.language = null;  
    },
    setChannel: (state, action) => {
      state.channel = action.payload;  
    },
    clearChannel: (state) => {
      state.channel = null;  
    },
  },
});

export const {
  addMessage,
  clearMessages,
  setSelectedLanguage,
  clearSelectedLanguage,
  setChannel, 
  clearChannel, 
} = conversationMessagesSlice.actions;

export default conversationMessagesSlice.reducer;
