
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  TextField,
  Box,
  Paper,
  Stack,
  IconButton,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  clearChannel,
  clearMessages,
  clearSelectedLanguage,
} from "app/store/fuse/conversationMessagesSlice";
import MicIcon from "@mui/icons-material/Mic";
import MicOffIcon from "@mui/icons-material/MicOff";
import socketConfig from "src/socketConfig";

const TextComponent = ({ sendPrompt, closeWS,language }) => {
  const [userInput, setUserInput] = useState("");
  const [isListening, setIsListening] = useState(false);
  const messages = useSelector((state) => state.fuse.conversations.messages);
  const channel = useSelector((state) => state.fuse.conversations.channel);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const videoRef = useRef(null);
  const { initializeAvatarSession,handleStreamReady } = socketConfig(videoRef); 

  let recognition;

  useEffect(() => {
    if (messages.length > 0) {
      const lastMessage = messages[messages.length - 1];

      if (lastMessage.type === "agent" && typeof lastMessage.message === "object" && lastMessage.message.token && channel == "ai_voice") {
        speakText(lastMessage.message.token);
      }
    }
  }, [messages]);

  useEffect(() => {
    if (channel === "ai_avatar" && videoRef.current) {
      initializeAvatarSession();
      videoRef.current.play();
    }
  }, [channel]);
  const speakText = (text) => {
    const synth = window.speechSynthesis;
  
    // Default to English if language is not set or unrecognized
    let lang = "en-US";
    let preferredVoiceName = "Google हिन्दी"
    if (language === "telugu") {
      lang = "te-IN";
      preferredVoiceName = "Google తెలుగు"
    } else if (language === "hindi") {
      lang = "hi-IN";
      preferredVoiceName = "Google हिन्दी"
    }
  
    // Cancel any ongoing speech before speaking
    synth.cancel();
  
    const utterance = new SpeechSynthesisUtterance(text);
    utterance.lang = lang;
  
    const setVoice = () => {
      const voices = synth.getVoices();
      
      // Try to find the preferred voice if specified
      let selectedVoice = voices.find((v) => v.name === preferredVoiceName);
  
      // If preferred voice is not found, find the first matching language voice
      if (!selectedVoice) {
        selectedVoice =
          voices.find((v) => v.lang === lang) ||
          voices.find((v) => v.lang.startsWith(lang.split("-")[0])) ||
          voices.find((v) => v.lang === "en-US") || // Fallback to English
          null;
      }
  
      utterance.voice = selectedVoice;
      synth.speak(utterance);
    };
  
    // If voices are already available, use them
    if (synth.getVoices().length > 0) {
      setVoice();
    } else {
      // Wait for voices to load before selecting
      synth.onvoiceschanged = setVoice;
    }
  };
  
  
  // Handle voice input
  const toggleMic = () => {
    if (isListening) {
      recognition?.stop();
      setIsListening(false);
    } else {
      startListening();
    }
  };

  const startListening = () => {
    if ("webkitSpeechRecognition" in window) {
      recognition = new window.webkitSpeechRecognition();
      recognition.continuous = false;
      recognition.interimResults = false;
      recognition.lang = "en-US";

      recognition.onstart = () => {
        setIsListening(true);
      };

      recognition.onresult = (event) => {
        const transcript = event.results[0][0].transcript;
        sendPrompt(transcript);
      };

      recognition.onerror = (event) => {
        console.error("Speech recognition error", event);
      };

      recognition.onend = () => {
        setIsListening(false);
      };

      recognition.start();
    } else {
      console.log("Speech recognition is not supported in this browser.");
    }
  };

  const handleSendClick = () => {
    if (userInput.trim()) {
      sendPrompt(userInput);
      setUserInput("");
    }
  };

  const handleEndSession = () => {
    closeWS();
    dispatch(clearMessages([]));
    dispatch(clearSelectedLanguage({}));
    dispatch(clearChannel());
    navigate("/knowledge_base");
  };

  return (
    <Box>
    <Paper elevation={3} sx={{ p: 4, borderRadius: 2 }}>
      <Box
        sx={{
          height: "65vh",
          overflowY: "auto",
          border: "1px solid",
          borderRadius: 1,
          p: 2,
          mb: 2,
        }}
      >
        {channel === "ai_avatar" ? (
          <article
            style={{
              width: "32vw" ,
              height: "55vh" ,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              overflow: "hidden",
              position: "relative",
            }}
          >
            <video
              ref={videoRef}
              id="avatarVideo"
              autoPlay
              playsInline
              style={{
                width:  "100%" ,
                height: "100%",
                objectFit: "contain",
                display: "block",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            ></video>
          </article>
        ) :  messages?.map((msg, index) => {
          let displayMessage = "";
          const isUserMessage = msg.type === "user";
          try {
            if (msg.type === "agent" && typeof msg.message === "object") {
              displayMessage = msg.message.token || "";
            } else {
              displayMessage = msg.message;
            }
          } catch (e) {
            console.error("Failed to parse agent message:", e);
            displayMessage = msg.message;
          }
  
          return (
            <Box
              key={index}
              sx={{
                display: "flex",
                justifyContent: isUserMessage ? "flex-end" : "flex-start",
                mb: 1,
              }}
            >
              <Box
                sx={{
                  p: 1,
                  borderRadius: 1,
                  maxWidth: 500,
                  bgcolor: isUserMessage ? "primary.main" : "grey.300",
                  color: isUserMessage ? "white" : "black",
                  wordWrap: "break-word",
                }}
              >
                {displayMessage}
              </Box>
            </Box>
          );
        })}
      </Box>
  
      <Stack direction="row" spacing={2} alignItems="center" sx={{ mt: 2 }}>
        {channel === "ai_voice" ? (
          // Voice Mode - Only Mic Button
          <IconButton onClick={toggleMic}>
            {isListening ? (
              <MicIcon sx={{ fontSize: 30, color: "red" }} />
            ) : (
              <MicOffIcon sx={{ fontSize: 30 }} />
            )}
          </IconButton>
        ) : (
          // AI Chat & Avatar Mode - Text Input & Buttons
          <>
            <TextField
              fullWidth
              value={userInput}
              onChange={(e) => setUserInput(e.target.value)}
              placeholder="Type your message..."
              variant="outlined"
              disabled={channel === "ai_voice"}
            />
            <Button variant="contained" onClick={handleSendClick}>
              Send
            </Button>
          </>
        )}
        <Button variant="outlined" color="error" onClick={handleEndSession}>
          Close
        </Button>
      </Stack>
    </Paper>
  </Box>
  
  );
};

export default TextComponent;

