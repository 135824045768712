import { lazy } from 'react';
import SocketComponent from './Socket';

const Product = lazy(()=>import('./Product/index'));

const KnowledgeBasicConfig = {
  settings: {
    layout: {},
  },
  routes: [
    {
      path: 'knowledge_base',
      element: <Product />,
      children: [
        {
          path: ':id',
          element: <SocketComponent />,
        },
       
      ],
    },
  ],
}

export default KnowledgeBasicConfig



