import { useEffect, useState } from "react";
import { FormProvider } from "react-hook-form";
import FusePageCarded from "@fuse/core/FusePageCarded";
import { useSelector } from "react-redux";
import socketConfig from "src/socketConfig";
import TextComponent from "./textComponent";

const SocketComponent = () => {
  const { connectToWS, sendPrompt, closeWS, isConnected,initializeAvatarSession } = socketConfig(); 
  const language = useSelector((state) => state.fuse.conversations.language)
  const messages = useSelector((state) => state.fuse.conversations.messages); 

   useEffect(() => {
      if (language?.agent_id) {
        connectToWS(language); 
      }
    }, [language]);
  
  return (
    <FormProvider>
      <FusePageCarded
        content={
          <div className="p-16 sm:p-24 w-full">
            <TextComponent 
              sendPrompt={sendPrompt}
              closeWS={closeWS}
              isConnected={isConnected}
              messages={messages}
              language={language}
              initializeAvatarSession={initializeAvatarSession}
            />
          </div>
        }
      />
    </FormProvider>
  );
};

export default SocketComponent;
