
import { useState, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { addMessage,setChannel,setSelectedLanguage } from 'app/store/fuse/conversationMessagesSlice';
import StreamingAvatar, { AvatarQuality } from '@heygen/streaming-avatar';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';

const socketConfig = (videoRef) => {
    const [isConnected, setIsConnected] = useState(false);
    const [messages, setMessages] = useState([]);
    const wsRef = useRef(null);
    const [language, setLanguage] = useState({ })
    const dispatch=useDispatch()
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

 useEffect(() => {
    if (language) {
    dispatch(setSelectedLanguage(language));
    }
  }, [language]);

    const connectToWS = (languageEvnt) => {
        const lang= languageEvnt ?? language
        if(!lang.agent_id) return 
        const ws = new WebSocket(`wss://ai-avatar-dev-843639668106.us-central1.run.app/agent/${lang.agent_id}`);
        wsRef.current = ws;

        ws.onopen = () => {
            console.log("Connected to WebSocket");
            setIsConnected(true);
            sendSetup();
        };

        ws.onmessage = (event) => {
            try {
                console.log("Received message:", event.data);
                const data = JSON.parse(event.data);
                console.log("Parsed data:", data);
                setMessages((prev) => [
                    ...prev,
                    { type: "agent", message: event.data },
                ]);
                dispatch(addMessage({ type: "agent", message: data }));
            } catch (error) {
                console.error("Failed to parse message:", event.data, error);
            }
        };

        ws.onclose = (e) => {
            console.log("WebSocket Disconnected",e);
            setIsConnected(false);
            // navigate("/")
        };

        ws.onerror = (error) => {
            console.error("WebSocket Error:", error);
        };

        return () => {
            ws.close();
        };
    };

    const sendSetup = () => {
        if (wsRef.current?.readyState === WebSocket.OPEN) {
            console.log("Sending setup message");
            sendMessage({ type: 'setup' });
        } else {
            console.warn("WebSocket is not ready for setup");
        }
    };

    const sendPrompt = (message) => {
        if (!wsRef.current) {
            console.warn("WebSocket reference is null. Cannot send message.");
            return;
        }
    
        if (wsRef.current.readyState === WebSocket.OPEN) {
            console.log("Sending prompt message:", message);
            setMessages((prev) => [...prev, { type: "user", message }]);
            // Store user message in Redux
            dispatch(addMessage({ type: "user", message }));
            sendMessage({ type: "prompt", prompt: message });
        } else {
            console.warn("WebSocket is not ready for prompt. Reconnecting...");
            connectToWS(language);  
            setTimeout(() => sendPrompt(message), 500);
        }
    };

    const sendMessage = (message) => {
        if (wsRef.current?.readyState === WebSocket.OPEN) {
            wsRef.current.send(JSON.stringify(message));
            console.log(`Message sent`, message);
        } else {
            console.warn("WebSocket is not connected. Cannot send message.");
        }
    };
    const closeWS = () => {
        if (wsRef.current && isConnected) {
            wsRef.current.close();
        }
    };
    const handleStreamReady = (event) => {
        if (event.detail && videoRef?.current) {
          // Stop any existing stream before setting a new one
          if (videoRef.current.srcObject) {
            const tracks = (videoRef.current.srcObject).getTracks();
            tracks.forEach((track) => track.stop());
          }
      
          videoRef.current.srcObject = event.detail;
          videoRef.current.onloadedmetadata = () => {
            videoRef.current?.play().catch((error) => {
              console.error("Video play failed:", error);
            });
          };
          setStreaming(true);
        } else {
          console.error("Stream is not available");
        }
      };

      const handleStreamDisconnected = () => {
        console.log("Stream disconnected");
        if (videoRef.current) {
            videoRef.current.srcObject = null;
        }
        setStreaming(false);
    }
    const terminateAvatarSession = async () => {
        if (!avatar || !sessionData) return;

        await avatar.stopAvatar();
        if (videoRef && videoRef.current) {
            videoRef.current.srcObject = null;
        }
        setAvatar(null)
    }
    
    const manipulateSelectionOnchange = (e) => {
        const langCode= e 
        switch (langCode) {
          case "tel":
            dispatch(setSelectedLanguage({
                agent_id: "67cfffb1966c0ebefd354669",
                avatarName: "ef08039a41354ed5a20565db899373f3",
                lang: "telugu",
                voice: {
                  voiceId: "8b06642340ad474e8d32b040928fe459",
                },
              }))
            break;
          case "hin":
            // 67b15f8019fed39fd1d84bc3
            dispatch(setSelectedLanguage({
                agent_id: "67b15f8019fed39fd1d84bc3",
                avatarName: "ef08039a41354ed5a20565db899373f3",
                lang: "hindi",
                // voice: {
                //     voiceId: '0b464b2f4e2249a4b5a05e60eaf41e7e'
                // }
              }))
            break;
          case "eng":
            dispatch(setSelectedLanguage({
                agent_id: "67ca29b772f3eb772200bbe7",
                avatarName: "ef08039a41354ed5a20565db899373f3",
                lang: "english",
              }))
           
            break;
          default:
            break;
        }
    }


    const fetchAccessToken = async ()=> {
        const apiKey = 'MDY0YTEwYjliZTY3NDliYzhlOWQzOTZmMDAxZjAzNWYtMTczOTYzODM3MQ==';
        const response = await fetch(
            "https://api.heygen.com/v1/streaming.create_token",
            {
                method: "POST",
                headers: { "x-api-key": apiKey },
            }
        );

        const { data } = await response.json();
        return data.token;
    }
    async function initializeAvatarSession() {
        const token = await fetchAccessToken();
        const initAvatar = new StreamingAvatar({ token });

        if (initAvatar) {
            let vConfig = { language: language.lang, avatarName: language.avatarName };
            if (language.voice) {
                vConfig = { ...vConfig, voice: { voiceId: language.voice.voiceId } }
            }
            const sessionData = await initAvatar.createStartAvatar({
                quality: AvatarQuality.High,
                // avatarName: "ef08039a41354ed5a20565db899373f3",
                // language: "telugu",
                ...vConfig
            });
            // setStreaming(true);
            initAvatar.on(StreamingEvents.STREAM_READY, handleStreamReady);
            initAvatar.on(StreamingEvents.STREAM_DISCONNECTED, handleStreamDisconnected);

            setAvatar(initAvatar)
            setSessionData(sessionData);
            console.log("Session data:", sessionData);

        }
    }

const handleTestClick = (chip) => {
  if(chip?.agentId== null) {
      enqueueSnackbar('Agent not presented', {
        variant: "error",
      })
    return;
  }
  
  if (chip?.agentId?.speaking_lang) {
    const extractedLanguage = chip.agentId.speaking_lang.trim().toLowerCase();
    let langCode;
    switch (extractedLanguage) {
      case "telugu":
        langCode = "tel";
        break;
      case "hindi":
        langCode = "hin";
        break;
      case "english":
        langCode = "eng";
        break;
      default:
        langCode = null;
    }
    if (langCode) {
      manipulateSelectionOnchange(langCode);
    } else {
      console.log("Language not recognized, no update to setLanguage.");
    }
    if (chip?.agentId?.channel) {
      dispatch(setChannel(chip?.agentId?.channel));
    }
  }else {
    enqueueSnackbar('Agent Language is presented', {
      variant: "error",
    })
  }
  navigate("/knowledge_base/new");
};
    return { isConnected, messages, sendPrompt, connectToWS, closeWS ,setMessages,manipulateSelectionOnchange,language,handleStreamReady,handleStreamDisconnected,terminateAvatarSession,initializeAvatarSession,handleTestClick};
};

export default socketConfig;
